import React from 'react'
import { css } from '@emotion/core'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'
import ContactUsForm from './ContactUsForm'
import { companyEmail } from '#globals/constants'

const styles = {
  contaner: css`
    background-color: ${colors.purple};
    ${breakPoints({
      paddingTop: [40, 40, 120, 120, 120],
      paddingBottom: [80, 80, 120, 120, 120],
    })};
  `,
  title: css`
    ${spacer.mrB20};
  `,
  text: css`
    line-height: 1.5;
    ${spacer.mrB30};
  `,
}
function Hero() {
  return (
    <section css={styles.contaner}>
      <div className='container'>
        <div className='row align-items-start'>
          <div className='col-lg-5 mb-4'>
            <Title
              size='xxxxLarge'
              color={colors.white}
              weight='bold'
              css={styles.title}
              as='h1'
            >
              Contact us
            </Title>
            <Text size='large' color={colors.white} css={styles.text}>
              Feel free to get in touch if you have any questions. We
              reply quickly!
              <br />
              <br />
              {/* <ExternalLink
                to='tel:+4402031264926'
                hoverColor='white'
                borderColor='white'
              >
                tel: +44 (0) 203 126 4926
              </ExternalLink>
              <br /> */}
              <ExternalLink
                to={`mailto:${companyEmail}`}
                hoverColor='white'
                borderColor='white'
              >
                email: {companyEmail}
              </ExternalLink>
              <br />
              <br />
              The Station Master&apos;s House,
              <br /> Isleworth,
              <br />
              TW7 4QE
            </Text>
          </div>
          <div className='col-lg-1' />
          <div className='col-lg-6'>
            <ContactUsForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
