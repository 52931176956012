import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import Wave from '@dqp/common/components/Wave/Wave'
import Hero from './Hero'

function ContactUs() {
  return (
    <>
      <Hero />
      <Wave bg={colors.purple} fill={colors.white} type='footer' />
    </>
  )
}

export default ContactUs
