import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import ContactUs from '#containers/ContactUs/ContactUs'
import heroimage from '#images/hero/contact_hero.png'

const SignupPage = () => (
  <PublicLayout headerColor={colors.purple}>
    <SEO
      title='Contact Us'
      meta={[{ name: 'theme-color', content: colors.purple }]}
      image={heroimage}
    />
    <ContactUs />
  </PublicLayout>
)

export default SignupPage
