import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import TextField from '@dqp/common/components/TextField/TextField'
import {
  ErrorText,
  SuccessText,
} from '@dqp/common/components/Text/Text'

const ContactSchema = object().shape({
  name: string().required('Name is required.'),
  email: string()
    .email('Invalid email.')
    .required('Email is required.'),
  message: string()
    .min(10, 'Please describe in atleast 10 characters.')
    .required('Message is required.'),
})
const SUBMIT_CONTACT_FORM_MUTATION = `
mutation submitContactForm($input: ContactFormInput){
  submitContactForm(input: $input) {
    message
    status
  }
}
`
function ContactUsForm() {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    handleChange,
    handleSubmit,
    values,
    errors: formErrors,
    isSubmitting,
    setFieldTouched,
    touched,
    resetForm,
  } = useFormik({
    validateOnBlur: true,
    validationSchema: ContactSchema,
    initialValues: {
      name: ``,
      email: ``,
      message: ``,
    },

    onSubmit: async (formData, actions) => {
      try {
        const { data, errors } = await fetch(
          process.env.GRAPHQL_ENDPOINT,
          {
            method: 'POST',
            headers: {
              'Content-Type': `application/json`,
            },
            body: JSON.stringify({
              query: SUBMIT_CONTACT_FORM_MUTATION,
              variables: { input: formData },
            }),
          },
        ).then(res => res.json())

        if (
          data &&
          data.submitContactForm &&
          data.submitContactForm.status === 'success'
        ) {
          setSuccessMessage(data.submitContactForm.message)
          resetForm()
          return
        }
        if (
          errors &&
          errors[0] &&
          errors[0].extensions &&
          errors[0].extensions.category === 'validation'
        ) {
          const validationErrors = Object.entries(
            errors[0].extensions.validation,
          ).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key.replace('input.', '')]: value.map(v =>
                v.replace('input.', ''),
              )[0],
            }),
            {},
          )
          actions.setErrors(validationErrors)
          return
        }

        if (errors && errors[0] && errors[0].message) {
          setErrorMessage(errors[0].message)
          return
        }
      } catch (error) {
        setErrorMessage('There was an error')
      }
    },
  })
  useEffect(() => {
    setErrorMessage('')
  }, [values])
  return (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={!!successMessage}>
        <TextField
          type='text'
          name='name'
          label='Name'
          labelProps={{ color: colors.white }}
          onChange={handleChange}
          onBlur={() => setFieldTouched('name', true)}
          value={values.name}
          error={touched.name && formErrors.name}
          css={spacer.mrB20}
        />
        <TextField
          type='text'
          name='email'
          label='Email'
          labelProps={{ color: colors.white }}
          onChange={handleChange}
          onBlur={() => setFieldTouched('email', true)}
          value={values.email}
          error={touched.email && formErrors.email}
          css={spacer.mrB20}
        />

        <TextField
          multiline
          name='message'
          label='Message'
          labelProps={{ color: colors.white }}
          onChange={handleChange}
          onBlur={() => setFieldTouched('message', true)}
          value={values.message}
          error={touched.message && formErrors.message}
          css={spacer.mrB20}
        />
        {errorMessage && (
          <ErrorText css={spacer.mrB20}>{errorMessage}</ErrorText>
        )}

        {successMessage && (
          <SuccessText css={spacer.mrB20} color={colors.white}>
            {successMessage}
          </SuccessText>
        )}
        <Button
          size='medium'
          variant='black'
          disabled={isSubmitting}
          type='submit'
          isLoading={isSubmitting}
        >
          SUBMIT
        </Button>
      </fieldset>
    </form>
  )
}

export default ContactUsForm
