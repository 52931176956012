import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import Text from '../Text/Text'
import { colors, spacer, misc } from '../../styles/utilities'

const styles = {
  field: css`
    display: flex;
    flex-direction: column;
  `,
  label: css`
    ${spacer.mrB5};
  `,
  inputContainer: hasError => css`
    border-radius: ${misc.borderRadius.md};
    box-shadow: ${misc.boxShadow.inputFieldWithBorder};
    background-color: ${colors.white};
    border: solid 3px ${hasError ? colors.red : 'transparent'};
    display: flex;
    align-items: center;
    padding: 0;
  `,
  input: css`
    line-height: initial;
    flex: auto;
    min-width: 0;
    color: inherit;
    font-size: inherit;
    border: none;
    outline: none;
    &::placeholder {
      color: ${colors.greyMedium};
    }
    ${spacer.padBT10};
    ${spacer.padLR10};
  `,
}

function TextField({
  className,
  name,
  onChange,
  type,
  value,
  prefix,
  suffix,
  placeholder,
  error,
  label,
  labelProps,
  errorProps,
  helpText,
  helpTextProps,
  multiline = false,
  ...rest
}) {
  const labelMarkup = label && (
    <Text {...labelProps} css={[spacer.mrB5, labelProps.css]}>
      {label}
    </Text>
  )

  const helpTextMarkup = helpText && (
    <Text {...helpTextProps} css={[spacer.mrT5, helpTextProps.css]}>
      {helpText}
    </Text>
  )

  const prefixMarkup = prefix && (
    <div css={spacer.padL10}>{prefix}</div>
  )

  const suffixMarkup = suffix && (
    <div css={spacer.padR10}>{suffix}</div>
  )

  const errorMakup = error && (
    <Text {...errorProps} css={[spacer.mrT5, errorProps.css]}>
      {error}
    </Text>
  )
  const InputTag = multiline ? 'textarea' : 'input'

  return (
    <label className={`d-flex flex-column ${className}`}>
      {labelMarkup}
      <div css={styles.inputContainer(error)}>
        {prefixMarkup}
        <InputTag
          placeholder={placeholder}
          name={name}
          type={multiline ? '' : type}
          value={value}
          onChange={onChange}
          size='medium'
          color={colors.charcoal}
          css={styles.input}
          {...rest}
        />
        {suffixMarkup}
      </div>
      {errorMakup}
      {helpTextMarkup}
    </label>
  )
}

export const TextFieldProps = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  error: PropTypes.node,
  errorProps: PropTypes.object,
  helpText: PropTypes.node,
  helpTextProps: PropTypes.object,
  labelProps: PropTypes.object,
  multiline: PropTypes.bool,
}

TextField.propTypes = TextFieldProps

TextField.defaultProps = {
  type: 'text',
  name: '',
  placeholder: '',
  className: '',
  prefix: null,
  suffix: null,
  error: null,
  errorProps: {
    as: `small`,
    size: `small`,
    color: colors.darkRed,
    weight: `bold`,
  },
  helpText: null,
  helpTextProps: {
    as: `small`,
    size: `small`,
    color: colors.grey,
    weight: `bold`,
  },
  label: '',
  labelProps: {
    color: colors.black,
  },
  multiline: false,
}

export default TextField
